import "./App.css";

function App() {
  return (
    <div>
      <header>
        <section>
          <h1>Nabeel Hassan</h1>
        </section>
        <section>
          <h2>Karchi, Pakistan</h2>
          <h4>Tel: +92-311-2388821; +92-301-0203765</h4>
        </section>
        <section>
          <p>
            Aiming to acquire a challenging position ia a organization, where
            the organization itself is always through a process of learning and
            where its people believe on common vission, teamwork and respect
            each other.
          </p>
        </section>
      </header>
      <main>
        <section>
          <h2>
            <u>WORKING EXPERIENCE</u>
          </h2>
          <div>
            <h3>
              <u>Cloud Architect</u>
            </h3>
            <h4>Duration: May 2021 - Present</h4>
            <h4>Organization: Freelancing on Fiverr</h4>
            <h4>Area of Work:</h4>
            <ul>
              <li>Management of client's AWS Cloud Infrastructure.</li>
              <li>
                Configuration of AWS Compute, Storage, Network, Indentity, CDN,
                Kubernetes and Container services for clients.
              </li>
            </ul>
          </div>
          <div>
            <h3>
              <u>IT Support Officer</u>
            </h3>
            <h4>Duration: November 2019 - Present</h4>
            <h4>Organization: Catcos/PrimeHR for State Bank of Pakistan</h4>
            <h4>Area of Work:</h4>
            <ul>
              <li>
                Managing Microsoft Active Directory's OUs, Users and Groups.
              </li>
              <li>
                Managing Microsoft Exchange's User Mailboxes, Distribution
                Lists, Shared Mailboxes and Databases.
              </li>
              <li>
                Configuration and Management of Symantec Endpoint Protection
                Antivirus.
              </li>
              <li>
                Configuration and Management of Symantec Endpoint Detection
                Response.
              </li>
              <li>
                Configuration and Management of Cisco Web Secuirty Appliances.
              </li>
              <li>
                Configuration and Management of VMs in VMware and Hyper-V
                environment.
              </li>
              <li>Administration of Windows Server 2012, 2016 and 2019.</li>
              <li>Monitoring of Data Centers, Network and UPS room.</li>
              <li>Execution of End of Day.</li>
            </ul>
          </div>
          <div>
            <h3>
              <u>Graphics & Web Desinger</u>
            </h3>
            <h4>Duration: December 2018 - Present</h4>
            <h4>Organization: Captivators Production</h4>
            <h4>Area of Work:</h4>
            <ul>
              <li>Management of Organization's websites.</li>
              <li>Management of Organization's Social Media profiles.</li>
              <li>Management of client's websites.</li>
              <li>Management of client's Social Media profiles.</li>
              <li>Creating Artworks for Organization.</li>
              <li>Creating Artworks for clients.</li>
            </ul>
          </div>
        </section>
        <section>
          <h2>
            <u>CERTIFICATIONS</u>
          </h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Institution</th>
                <th>Completion Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Oracle Cloud Infrastructure 2019 Cloud Operations Associate
                </td>
                <td>Oracle</td>
                <td>May 2020</td>
              </tr>
              <tr>
                <td>Oracle Cloud Infrastructure 2019 Architect Associate</td>
                <td>Oracle</td>
                <td>May 2020</td>
              </tr>
              <tr>
                <td>Oracle Cloud Infrastructure 2019 Architect Professional</td>
                <td>Oracle</td>
                <td>May 2020</td>
              </tr>
              <tr>
                <td>Oracle Cloud Infrastructure Developer 2020 Associate</td>
                <td>Oracle</td>
                <td>May 2020</td>
              </tr>
              <tr>
                <td>Oracle Cloud Infrastructure Foundations 2020 Associate</td>
                <td>Oracle</td>
                <td>May 2020</td>
              </tr>
              <tr>
                <td>NSE 2 Network Security Associate</td>
                <td>Fortinet</td>
                <td>May 2020</td>
              </tr>
              <tr>
                <td>NDG Linux Unhatched</td>
                <td>Cisco</td>
                <td>April 2020</td>
              </tr>
              <tr>
                <td>NSE 1 Network Security Associate</td>
                <td>Fortinet</td>
                <td>April 2020</td>
              </tr>
              <tr>
                <td>Introduction to Cybersecurity</td>
                <td>Cisco</td>
                <td>April 2020</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h2>
            <u>COURSES</u>
          </h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Institution</th>
                <th>Completion Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cloud Native Computing</td>
                <td>PIAIC</td>
                <td>May 2021</td>
              </tr>
              <tr>
                <td>Azure Administrator and Secuirty</td>
                <td>Sherdil</td>
                <td>November 2020</td>
              </tr>
              <tr>
                <td>Google Architect and Administration</td>
                <td>Sherdil</td>
                <td>November 2020</td>
              </tr>
              <tr>
                <td>AWS Architect, Operations and Development</td>
                <td>Sherdil</td>
                <td>November 2020</td>
              </tr>
              <tr>
                <td>Diploma in Graphics and Web Designing</td>
                <td>Arena</td>
                <td>November 2019</td>
              </tr>
              <tr>
                <td>Advance Optical Fiber Technology</td>
                <td>Telecom Foundation and Training Institute</td>
                <td>January 2019</td>
              </tr>
              <tr>
                <td>Optical Fiber Transmission System</td>
                <td>Telecom Foundation and Training Institute</td>
                <td>December 2018</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h2>
            <u>ACADEMIC QUALIFICATION</u>
          </h2>
          <h3>
            <u>Virtual University of Pakistan</u>
          </h3>
          <p>In 7th Semester of Bachelor of Science in Computer Science.</p>
          <h3>
            <u>Jinnah Polytechnic Institute</u>
          </h3>
          <p>
            Completed 3-year Diploma in Associate Engineering of
            Telecommunication.
          </p>
        </section>
      </main>
      <footer>
        <section>
          <h2>
            <u>PERSONAL SKILLS</u>
          </h2>
          <ul>
            <li>Team-Player with excellent communication skills.</li>
            <li>Quick learner.</li>
            <li>
              Self-motivated with a strong work ethic for professionalism and
              timeliness.
            </li>
            <li>Motivated self-starter with a strong desire to learn.</li>
          </ul>
        </section>
        <section>
          <h2>
            <u>PERSONAL INFORMATION</u>
          </h2>
          <table>
            <tbody>
              <tr>
                <td>Father Name:</td>
                <td>Abid Pervez</td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td>January 5, 1998</td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>House# A-94, Block-S, North Nazimabad, Karachi</td>
              </tr>
            </tbody>
          </table>
          <p>
            Languages:
            <ul>
              <li>English</li>
              <li>Urdu</li>
            </ul>
          </p>
        </section>
      </footer>
    </div>
  );
}

export default App;
